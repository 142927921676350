<script setup lang="ts">

</script>

<template>
<section class="flex flex-col">
    <svg viewBox="0 0 1200 80" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <path
            d="M0 60L66.7 51.5C133.3 43 266.7 26 400 27.3C533.3 28.7 666.7 48.3 800 59.8C933.3 71.3 1066.7 74.7 1133.3 76.3L1200 78L1200 81L1133.3 81C1066.7 81 933.3 81 800 81C666.7 81 533.3 81 400 81C266.7 81 133.3 81 66.7 81L0 81Z"
            fill="#F0EADE" stroke-linecap="round" stroke-linejoin="miter"></path>
    </svg>
    <div class="bg-brand-beige">
        <div class="container py-8 flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2">
                <div class="my-2 pb-2 mx-auto w-3/4 text-3xl font-medium">
                    Få eksklusive tilbud og nyheder direkte i din indbakke!
                </div>
            </div>
            <div class="w-full lg:w-1/2">
                <div class="klaviyo-form-RJir8j mx-auto w-3/4"></div>
            </div>
        </div>
    </div>
</section>
</template>
